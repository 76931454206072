import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppConfig} from "../config/app-config";

type ImageProvider = 'contentful' | 'prismic' | 'external';

@Component({
  selector: 'responsive-image',
  templateUrl: './responsive-image.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveImageComponent implements OnInit, OnChanges {

  @Input() lazy = true;

  @Input() url: string;
  @Input() maxWidth: number;
  @Input() maxHeight: number;
  @Input() alt: string = '';
  @Input() aspectRatio: number = 0;

  @Input() round: boolean;
  @Input() overlay: boolean;
  @Input() params: string;


  breakpoints = [400];

  image: string;
  base: string;
  retina2: string;
  retina3: string;

  sources = [];

  provider: ImageProvider = 'external';

  loading = 'eager'

  constructor(public config: AppConfig, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!this.url)
      return;

    if (this.lazy)
      this.loading = 'lazy'

    // get image provider
    if (this.url.includes('images.prismic.io') || this.url.includes('prismic-io.s3.amazonaws.com'))
      this.provider = 'prismic';
    else if (this.url.includes('images.ctfassets.net'))
      this.provider = 'contentful';

    // set images on server
    this.setImageUrls(this.maxWidth, this.maxHeight);
  }


  setImageUrls(maxWidth: number, maxHeight: number) {


    let seperator = '?';

    // replace h= und w= (for prismic cropping)
    if (this.provider == 'prismic' && this.url.includes('rect='))
      this.url = this.url.replace(/[\?&]h=[^&]+/, '').replace(/[\?&]w=[^&]+/, '');

    if (this.url.includes('?')) {
      seperator = '&';
    }

    // create image url

    this.image = this.url + seperator + 'w={{width}}';

    if (maxHeight > 0) {
      this.image += '&h={{height}}';
    }

    if (this.params) {
      this.image += '&' + this.params;
    }


    // create image variants
    this.base = this.image.replace('{{width}}', '' + maxWidth).replace('{{height}}', '' + maxHeight);
    this.retina2 = this.image.replace('{{width}}', '' + maxWidth * 2).replace('{{height}}', '' + maxHeight * 2);
    this.retina3 = this.image.replace('{{width}}', '' + maxWidth * 3).replace('{{height}}', '' + maxHeight * 3);

    for (let breakpoint of this.breakpoints) {
      if (breakpoint < this.maxWidth) {
        this.sources.push({
          breakpoint: breakpoint,
          base: this.image.replace('{{width}}', '' + breakpoint).replace('{{height}}', '' + maxHeight),
          retina2: this.image.replace('{{width}}', '' + breakpoint * 2).replace('{{height}}', '' + maxHeight * 2),
          retina3: this.image.replace('{{width}}', '' + breakpoint * 3).replace('{{height}}', '' + maxHeight * 3)
        })
      }
    }
  }


}
