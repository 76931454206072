import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ResponsiveImageComponent} from "./responsive-image.component";


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ResponsiveImageComponent],
  exports: [ResponsiveImageComponent],
  providers: []
})
export class ResponsiveImageModule {
}
